<template>
  <b-row>
    <b-col cols="2">
      <p>{{ field.label }}</p>
    </b-col>
    <b-col cols="5">
      <b-form-select
        size="sm"
        :options="Options"
        :value="value.filter_payment_type_condition"
        @input="updateModel($event, 'filter_payment_type_condition')"
      />
    </b-col>
    <b-col cols="5">
      <b-form-input
        placeholder="Введите данные"
        size="sm"
        :value="value.filter_payment_type"
        @input="updateModel($event, 'filter_payment_type')"
      />
    </b-col>
  </b-row>
</template>
<script>
import filterOptions from "@/utils/constants/filerConstants/parametrs";
export default {
  props: ["value", "field"],
  name: "payment_type",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      Options: filterOptions,
    };
  },
  methods: {
    updateModel(value, name) {
      if (
        name === "filter_payment_type" ||
        name === "filter_payment_type_condition"
      ) {
        if (value === "") {
          value = null;
        }
        this.$emit("change", { val: value, field: name });
      }

      this.$store.state.filterPlus.filterPlus = null;
    },
  },
  mounted() {
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "payment_type" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "filter_payment_type";
      this.updateModel(value, name);
    }
  },
};
</script>
