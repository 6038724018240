<template>
  <b-row class="mb-1">
    <b-col cols="2">
      <p>{{ field.label }}</p>
    </b-col>
    <b-col cols="5">
      <label for="" style="display: block; text-align: right">От</label>
      <date-picker
        v-model="value.start_date"
        @input="updateModel($event, 'start_date')"
        type="date"
        class="w-100"
        v-mask="'####-##-##'"
        format="YYYY-MM-DD"
        :value-type="'YYYY-MM-DD'"
        placeholder="YYYY-MM-DD"
      >
      </date-picker>
    </b-col>
    <b-col cols="5">
      <label for="" style="display: block; text-align: right">До</label>
      <date-picker
        v-model="value.end_date"
        @input="updateModel($event, 'end_date')"
        type="date"
        class="w-100"
        v-mask="'####-##-##'"
        format="YYYY-MM-DD"
        :value-type="'YYYY-MM-DD'"
        placeholder="YYYY-MM-DD"
      >
      </date-picker>
    </b-col>
  </b-row>
</template>

<script>
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  props: ["value", "field"],
  name: "date",
  model: {
    prop: "value",
    event: "change",
  },
  methods: {
    updateModel(event, field) {
      this.$emit("change", {
        ...this.value,
        [field]: event,
      });
    },
  },
  mounted() {
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "start_date" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "start_date";
      this.updateModel(value, name);
    }
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "end_date" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "end_date";
      this.updateModel(value, name);
    }
  },
};
</script>

<style>
.mx-calendar-content td,
.mx-calendar-content th {
  width: auto !important;
  text-align: center !important;
  /* min-width: fit-content !important */
}
</style>
