export default [
  {
    path: "/",
    name: "profile",
    component: () => import("@/views/default/Profile.vue"),
  },
  {
    path: "/error-403",
    name: "error-403",
    component: () => import("@/views/error/Error403.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      action: "read",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/pages/authentication/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/pages/authentication/Login.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
    },
  },
  {
    path: "/dashboard/order-count",
    name: "dashboard-order",
    component: () => import("@/views/default/analytics/EchartStackedArea.vue"),
  },
  {
    path: "/dashboard/order-requests",
    name: "dashboard-requests",
    component: () => import("@/views/default/analytics/orderRequests.vue"),
  },
  {
    path: "/dashboard/qr-cod",
    name: "dashboard/qr-cod",
    component: () => import("@/views/default/analytics/qrCod.vue"),
  },
  {
    path: "/driversAnalytic",
    name: "driversAnalytic",
    component: () => import("@/views/default/analytics/driversAnalytic.vue"),
  },
  {
    path: "/slags/count",
    name: "slags/count",
    component: () => import("@/views/default/analytics/slagsCount.vue"),
  },
  {
    path: "/order/index",
    name: "orders",
    meta: {
      filterTemplate: [
        {
          name: "Полный список",
          data: {
            limit: 100,
          },
        },
        {
          name: "В работе",
          data: {
            filter_menu: "IN_WORK",
            limit: 100,
          },
        },
        {
          name: "Предварительные",
          data: {
            filter_menu: "PREV_ORDERS",
            limit: 100,
          },
        },
        {
          name: "Мои заказы",
          data: {
            filter_menu: "MY_ORDERS",
            limit: 100,
          },
        },
        {
          name: "Мои закрытые",
          data: {
            filter_menu: "MY_CLOSED_ORDERS_LESS_20_MINUTES",
            limit: 100,
          },
        },
        {
          name: "Закрытые",
          data: {
            filter_menu: "CLOSED_ORDERS_LESS_20_MINUTES",
            limit: 100,
          },
        },
        {
          name: "Чужие заказы",
          data: {
            filter_menu: "OTHER_ORDERS_LESS_20_MINUTES",
            limit: 100,
          },
        },
        {
          name: "Зависшие заказы",
          data: {
            filter_menu: "NO_CLOSED_ORDERS_MORE_24_HOUR",
            limit: 100,
          },
        },
      ],
    },
    component: () => import("@/views/default/order/index.vue"),
  },
  {
    path: "/orderJournals/index",
    name: "orders/journals",
    component: () => import("@/views/default/orderJournals/index.vue"),
    meta: {
      filterTemplate: [
        {
          name: "Полный список",
          data: {
            filter_menu: "ALL",
            limit: 100,
          },
        },
        {
          name: "Полный список(В работе)",
          data: {
            filter_menu: "ALL_IN_WORK",
            limit: 100,
          },
        },
        {
          name: "Архив",
          data: {
            filter_menu: "ARCHIVE",
            limit: 100,
          },
        },
        {
          name: "Чужие заказы",
          data: {
            filter_menu: "OTHER_ORDERS",
            limit: 100,
          },
        },
        {
          name: "Чужие водители",
          data: {
            filter_menu: "OTHER_DRIVERS",
            limit: 100,
          },
        },
        {
          name: "Чужие заказы(архив)",
          data: {
            filter_menu: "OTHER_ORDERS_ARCHIVE",
            limit: 100,
          },
        },
        {
          name: "Заказы выполненные чужими водителями",
          data: {
            filter_menu: "OTHER_DRIVERS_ARCHIVE",
            limit: 100,
          },
        },
      ],
    },
  },
  {
    path: "/order/map",
    name: "orders-map/orders",
    component: () => import("@/views/default/orderMap/index.vue"),
  },
  {
    path: "/order/reason",
    name: "settings-order/cancel-reason",
    component: () => import("@/views/default/orderReason/index.vue"),
  },
  {
    path: "/client",
    name: "clients",
    component: () => import("@/views/default/client/index.vue"),
  },
  {
    path: "/clients/balances",
    name: "clients/balances",
    component: () => import("@/views/default/client/personalAccounts.vue"),
  },
  {
    path: "/bonus-history",
    name: "bonus-history",
    component: () => import("@/views/default/client/history/index.vue"),
  },
  {
    path: "/taxi/driver",
    name: "drivers",
    component: () => import("@/views/default/taxi/driver/index.vue"),
    meta: {
      filterTemplate: [
        {
          name: "Полный список",
          data: {
            limit: 100,
          },
        },
        {
          name: "В работе",
          data: {
            filter_status_performer: 1,
            limit: 100,
          },
        },
      ],
    },
  },
  {
    path: "/taxi/cars",
    name: "cars",
    component: () => import("@/views/default/taxi/cars/index.vue"),
    meta: {
      filterTemplate: [
        {
          name: "Полный список",
          data: {
            filter_journal_car: "ALL_LIST",
            limit: 100,
          },
        },
        {
          name: "В работе",
          data: {
            filter_journal_car: "ACTIVE",
            limit: 100,
          },
        },
        {
          name: "Уволены",
          data: {
            filter_journal_car: "DISMISSED",
            limit: 100,
          },
        },
        {
          name: "Созданные мной",
          data: {
            filter_journal_car: "CREATED_BY_ME",
            limit: 100,
          },
        },
        {
          name: "Осмотр в офисе",
          data: {
            filter_journal_car: "INSPECTION_IN_THE_OFFICE",
            limit: 100,
          },
        },
        {
          name: "Дубли",
          data: {
            filter_journal_car: "DOUBLES",
            limit: 100,
          },
        },
      ],
    },
  },
  {
    path: "/carStateCurrent/index",
    name: "car-state/current",
    component: () => import("@/views/default/carStateCurrent/index.vue"),
    meta: {
      filterTemplate: [
        {
          name: "Все",
          data: {
            filter_menu: "ALL",
            limit: 100,
          },
        },
        {
          name: "Свободные",
          data: {
            filter_menu: "FREE",
            limit: 100,
          },
        },
        {
          name: "На смене",
          data: {
            filter_menu: "WORKING",
            limit: 100,
          },
        },
        {
          name: "Не работают",
          data: {
            filter_menu: "NOT_WORKING",
            limit: 100,
          },
        },
        {
          name: "Не назначать",
          data: {
            filter_menu: "NOT_ASSIGN",
            limit: 100,
          },
        },
      ],
    },
  },
  {
    path: "/car-state",
    name: "car-state",
    component: () => import("@/views/default/carState/index.vue"),
    meta: {
      filterTemplate: [
        {
          name: "Состояние автомобиля",
          data: {
            filter_menu: "car_state",
            limit: 100,
          },
        },
        {
          name: "Архив состояния автомобиля",
          data: {
            filter_menu: "archive",
            limit: 100,
          },
        },
      ],
    },
  },
  {
    path: "/taxi/memos/index",
    name: "memos",
    component: () => import("@/views/default/taxi/memos/index.vue"),
  },
  {
    path: "/drivers/memos",
    name: "driversMemos",
    component: () => import("@/views/default/taxi/driversMemos/index.vue"),
  },
  {
    path: "/taxi/forms/index",
    name: "driver-profiles",
    component: () => import("@/views/default/taxi/forms/index.vue"),
    meta: {
      filterTemplate: [
        {
          name: "В работе (Текущие)",
          data: {
            filter_state: "IN_WORK",
            limit: 100,
          },
        },
        {
          name: "Полный список",
          data: {
            filter_state: "ALL",
            limit: 100,
          },
        },
        {
          name: "В работе (Все)",
          data: {
            filter_state: "IN_WORK_ALL",
            limit: 100,
          },
        },
        {
          name: "Мои анкеты (Текущие)",
          data: {
            filter_state: "MY_PROFILES_IN_WORK",
            limit: 100,
          },
        },
        {
          name: "Мои анкеты (Все)",
          data: {
            filter_state: "MY_PROFILES_ALL",
            limit: 100,
          },
        },
      ],
    },
  },
  {
    path: "/taxi/rating/index",
    name: "rating",
    component: () => import("@/views/default/taxi/rating/index.vue"),
  },
  {
    path: "/asterisk-api/history-call",
    name: "asterisk-api/history-call",
    component: () => import("@/views/default/sipHistory.vue"),
    meta: {
      filterTemplate: [
        {
          name: "Полный список",
          data: {
            filter_menu: "ALL",
            limit: 100,
          },
        },
        {
          name: "Принятые",
          data: {
            filter_menu: "ANSWERED",
            limit: 100,
          },
        },
        {
          name: "Полный список(архив)",
          data: {
            filter_menu: "ALL_ARCHIVE",
            limit: 100,
          },
        },
        {
          name: "Принятые(архив)",
          data: {
            filter_menu: "ANSWERED_ARCHIVE",
            limit: 100,
          },
        },
        {
          name: "Звонки по заказу",
          data: {
            filter_menu: "CALL_WITH_ORDERS",
            limit: 100,
          },
        },
      ],
    },
  },

  {
    path: "/city/zone/index",
    name: "addresses/zones",
    component: () => import("@/views/default/city/zone/index.vue"),
  },
  {
    path: "/city/country/index",
    name: "addresses/countries",
    component: () => import("@/views/default/city/country/index.vue"),
  },
  {
    path: "/admin/address",
    name: "admin/address",
    component: () => import("@/views/default/city/adminAddress/index.vue"),
  },
  {
    path: "/city",
    name: "addresses/cities",
    component: () => import("@/views/default/city/city/index.vue"),
  },
  {
    path: "/city/region/index",
    name: "addresses/regions",
    component: () => import("@/views/default/city/region/index.vue"),
  },
  {
    path: "/city/villages/index",
    name: "addresses/villages",
    component: () => import("@/views/default/city/villages/index.vue"),
  },
  {
    path: "/city/village-type/index",
    name: "addresses/village-type",
    component: () => import("@/views/default/city/villageType/index.vue"),
  },
  {
    path: "/city/district/index",
    name: "addresses/districts",
    component: () => import("@/views/default/city/district/index.vue"),
  },
  {
    path: "/city/street/index",
    name: "addresses/streets",
    component: () => import("@/views/default/city/street/index.vue"),
  },
  {
    path: "/city/streetTypes/index",
    name: "addresses/street-type",
    component: () => import("@/views/default/city/streetTypes/index.vue"),
  },
  {
    path: "/city/address/index",
    name: "addresses/addresses",
    component: () => import("@/views/default/city/address/index.vue"),
  },
  {
    path: "/city/address/not-found",
    name: "addresses/not_found",
    component: () => import("@/views/default/city/address/not-found.vue"),
  },
  {
    path: "/city/fastAddresses/index",
    name: "addresses/fast/addresses",
    component: () => import("@/views/default/city/fastAddresses/index.vue"),
  },
  {
    path: "/city/fastAddressCategory/index",
    name: "addresses/fast/addresses/categories",
    component: () =>
      import("@/views/default/city/fastAddressCategory/index.vue"),
  },
  {
    path: "/tariff/allowances",
    name: "tariff/allowances",
    component: () =>
      import("@/views/default/tariff/tariffAllowances/index.vue"),
  },
  {
    path: "/tariff/allowance/index",
    name: "v2/allowances",
    component: () => import("@/views/default/tariff/allowance/index.vue"),
  },

  {
    path: "/tariff/infoAllowances",
    name: "infoAllowances",
    component: () => import("@/views/default/information/infoAllowances.vue"),
  },

  {
    path: "/tariff/infoStatuses",
    name: "infoStatuses",
    component: () => import("@/views/default/information/infoStatuses.vue"),
  },

  {
    path: "/tariff/types",
    name: "tariff/types",
    component: () => import("@/views/default/tariff/type/index.vue"),
  },
  {
    path: "/tariff/clientsTypes",
    name: "tariff/clientsTypes",
    component: () => import("@/views/default/tariff/clientTariffs/index.vue"),
  },
  {
    path: "/tariff/calcType/index",
    name: "tariff/calc-types",
    component: () => import("@/views/default/tariff/calcType/index.vue"),
  },
  {
    path: "/driver-profile-causes",
    name: "driver-profile-causes",
    component: () =>
      import("@/views/default/tariff/reasonProcessing/index.vue"),
  },
  {
    path: "/car-conditions",
    name: "car-settings/car-conditions",
    component: () => import("@/views/default/taxi/cars/conditionCar.vue"),
  },
  // {
  //   path: '/tariff/reasonStatus/index',
  //   name: 'tariff/driver-profile-statuses',
  //   component: () => import('@/views/default/tariff/reasonStatus/index.vue')
  // },
  {
    path: "/categoryViolation/index",
    name: "categoryviolation",
    component: () =>
      import("@/views/default/tariff/categoryViolation/index.vue"),
  },
  {
    path: "/main/tariff/client",
    name: "main/tariff/client",
    meta: {
      filterDynamic: [
        {
          name: "Базовый тарифы",
          data: {
            component: "tariff-client-base",
            name: "base-tariffs",
          },
        },
        {
          name: "Рабочий",
          data: {
            component: "tariff-client-work",
            name: "base-tariffs/working",
          },
        },
        {
          name: "Полный список",
          data: {
            component: "tariff-client-full-list",
            name: "base-tariffs/all",
          },
        },
        {
          name: "Архив тарифов",
          data: {
            component: "tariff-client-archive",
            name: "base-tariffs/archive",
          },
        },
      ],
    },
    component: () => import("@/views/default/tariff/tariff/index.vue"),
  },
  {
    path: "/base-tariffs",
    name: "base-tariffs",
    component: () =>
      import("@/views/default/tariff/tariff/clientBase/index.vue"),
  },
  {
    path: "/base-tariffs/working",
    name: "base-tariffs/working",
    component: () =>
      import("@/views/default/tariff/tariff/clientWork/index.vue"),
  },
  {
    path: "/base-tariffs/all",
    name: "base-tariffs/all",
    component: () =>
      import("@/views/default/tariff/tariff/clientFullList/index.vue"),
  },
  {
    path: "/base-tariffs/archive",
    name: "base-tariffs/archive",
    component: () =>
      import("@/views/default/tariff/tariff/clientArchive/index.vue"),
  },
  {
    path: "/main",
    name: "main",
    meta: {
      filterDynamic: [
        {
          name: "Базовый тарифы",
          data: {
            component: "tariff-performers-base",
            name: "tariff-performers/base",
          },
        },
        {
          name: "Рабочий",
          data: {
            component: "tariff-work-performers",
            name: "tariff-performers",
          },
        },
      ],
    },
    component: () =>
      import(
        "@/views/default/tariff/mainTariffPerformer/mainTariffPerformer.vue"
      ),
  },
  {
    path: "/tariff-performers/base",
    name: "tariff-performers/base",
    component: () => import("@/views/default/tariff/tariffPerformer/index.vue"),
  },
  {
    path: "/tariff-performers",
    name: "tariff-performers",
    component: () =>
      import("@/views/default/tariff/tariffWorkPerformer/index.vue"),
  },
  {
    path: "/performer-request-types",
    name: "performer-request-types",
    component: () =>
      import("@/views/default/tariff/performerRequestType/index.vue"),
  },
  {
    path: "/tariff/settings",
    name: "tariff/settings",
    component: () => import("@/views/default/tariff/tariffSettings/index.vue"),
  },
  {
    path: "/tariff/settings/work",
    name: "tariff/settings/work",
    component: () =>
      import("@/views/default/tariff/tariffSettingsWork/index.vue"),
  },
  {
    path: "/tariff/price/work",
    name: "tariff/price/work",
    component: () => import("@/views/default/tariff/tariffPriceWork/index.vue"),
  },
  {
    path: "/tariff/prices",
    name: "tariff/prices",
    component: () => import("@/views/default/tariff/tariffPrices/index.vue"),
  },
  {
    path: "/tariff/category",
    name: "category-tariff",
    component: () => import("@/views/default/tariff/tariffCategory/index.vue"),
  },
  {
    path: "/tariff/tariffClassCar",
    name: "tariff-class-car-settings",
    component: () => import("@/views/default/tariff/tariffClassCar/index.vue"),
  },
  {
    path: "/tariff/validatyPeriod/index",
    name: "tariff/validatyPeriod",
    component: () => import("@/views/default/tariff/validatyPeriod/index.vue"),
  },

  {
    path: "/tariff/memoSubject/index",
    name: "memo-subjects",
    component: () => import("@/views/default/tariff/memoSubject/index.vue"),
  },
  {
    path: "/tariff/memoType/index",
    name: "memo-types",
    component: () => import("@/views/default/tariff/memoType/index.vue"),
  },
  {
    path: "/tariff/memoCauses",
    name: "memo-causes",
    component: () => import("@/views/default/settings/memoCauses/index.vue"),
  },
  {
    path: "/commission",
    name: "commission",
    component: () => import("@/views/default/tariff/commission/index.vue"),
  },
  {
    path: "/staff/employe/index",
    name: "employee",
    component: () => import("@/views/default/staff/employe/index.vue"),
  },
  {
    path: "/staff/access/index",
    name: "access",
    component: () => import("@/views/default/staff/access/index.vue"),
  },
  {
    path: "/staff/route/index",
    name: "routes",
    component: () => import("@/views/default/staff/route/index.vue"),
  },
  {
    path: "/staff/position/index",
    name: "position",
    component: () => import("@/views/default/staff/position/index.vue"),
  },
  {
    path: "/staff/department/index",
    name: "employee-group",
    component: () => import("@/views/default/staff/department/index.vue"),
  },
  {
    path: "/driver/carInfo",
    name: "carInfo",
    component: () => import("@/views/default/information/info.vue"),
  },
  {
    path: "/form-time",
    name: "time-form",
    component: () => import("@/views/default/information/formTime.vue"),
  },
  {
    path: "/driver/balance",
    name: "balance",
    component: () => import("@/views/default/information/balance.vue"),
  },
  {
    path: "/position/accesses",
    name: "chapter",
    component: () => import("@/views/default/information/accesses.vue"),
  },
  {
    path: "/position/info",
    name: "position",
    component: () => import("@/views/default/information/position.vue"),
  },
  {
    path: "/positionAccess",
    name: "positionAccess",
    component: () => import("@/views/default/information/positionAccess.vue"),
  },
  {
    path: "/accessesEmployee",
    name: "accessesEmployee",
    component: () => import("@/views/default/information/employeAccesses.vue"),
  },
  {
    path: "/driver/driverInfo",
    name: "driverInfo",
    component: () => import("@/views/default/taxi/carInfo/driverInfo.vue"),
  },
  {
    path: "/cars/changeHistory",
    name: "carChangeHistory",
    component: () =>
      import("@/views/default/taxi/carInfo/carChangeHistory.vue"),
  },
  // {
  //   path: '/cars/photo-controls',
  //   name: 'photo-controls',
  //   component: ()=> import('@/views/default/taxi/carInfo/photocontrole.vue')
  // },
  {
    path: "/driver/photo",
    name: "driversPhotocontrol",
    component: () =>
      import("@/views/default/taxi/photoControl/driverPhoto.vue"),
  },
  {
    path: "/car-settings/body-types",
    name: "car-settings/body-types",
    component: () => import("@/views/default/taxi/cars/body-type.vue"),
  },
  {
    path: "/photo-control/type",
    name: "photo-control/type",
    component: () =>
      import("@/views/default/tariff/photoControlTypes/index.vue"),
  },
  {
    path: "/car-settings/brands",
    name: "car-settings/brands",
    component: () => import("@/views/default/taxi/cars/carBrand.vue"),
  },
  {
    path: "/SystemSettings/brands",
    name: "SystemSettings/brands",
    component: () =>
      import("@/views/default/taxi/cars/systemSettingsBrands.vue"),
  },
  {
    path: "/api/organizations",
    name: "organizations",
    component: () =>
      import("@/views/default/taxi/organization/typeOrganization.vue"),
  },
  {
    path: "/taxi/photo-control",
    name: "photo-control/performer-assign/search",
    component: () => import("@/views/default/taxi/photoControl/index.vue"),
  },
  {
    path: "/taxi/photo-controlInfo",
    name: "photo-control/performer-assign/searchInfo",
    component: () =>
      import("@/views/default/taxi/photoControl/photocontrolInfo.vue"),
  },
  {
    path: "/order/assigment",
    name: "report/assigment",
    component: () => import("@/views/default/information/assigmentLog.vue"),
  },
  {
    path: "/taxi/assigment-log",
    name: "report/assigment-log",
    component: () => import("@/views/default/taxi/assigmnet/index.vue"),
  },
  {
    path: "/documentation/historyFlesh",
    name: "documents/performers-transactions",
    meta: {
      filterTemplate: [
        {
          name: "Полный список",
          data: {
            filter_menu: "ALL_LIST",
            limit: 100,
          },
        },
        {
          name: "Мои платежи",
          data: {
            filter_menu: "MY_PAYMENTS",
            limit: 100,
          },
        },
        {
          name: "Архив (старее 3-х мес)",
          data: {
            filter_menu: "ARCHIVE_OLDER_THEN_3_MONTHS;",
            limit: 100,
          },
        },
        {
          name: "Кассовые платежи",
          data: {
            filter_menu: "CASH_PAYMENTS",
            limit: 100,
          },
        },
        {
          name: "Кассовые платежи к выплате",
          data: {
            filter_menu: "CASH_PAYMENTS_DUE",
            limit: 100,
          },
        },
        {
          name: "Потверждения на странице",
          data: {
            filter_menu: "FOR_CONFIRMATION",
            limit: 100,
          },
        },
        {
          name: "Платежи ПС",
          data: {
            filter_menu: "PAYMENTS_PS",
            limit: 100,
          },
        },
      ],
    },
    component: () => import("@/views/default/document/historyFlesh/index.vue"),
  },
  {
    path: "/promo-code/type",
    name: "promo-code/type",
    component: () => import("@/views/default/document/typePromocode/index.vue"),
  },
  {
    path: "/promo-code",
    name: "promo-code",
    component: () =>
      import("@/views/default/document/journalPromocode/index.vue"),
    meta: {
      filterTemplate: [
        {
          name: "Полный список",
          data: {
            filter_menu: "ALL",
            limit: 100,
          },
        },
        {
          name: "Полный список (в работе)",
          data: {
            filter_menu: "ALL_IN_WORK",
            limit: 100,
          },
        },
      ],
    },
  },
  {
    path: "/promo-code/activations",
    name: "promo-code/activations",
    component: () => import("@/views/default/document/activePromocede.vue"),
  },
  {
    path: "/promo-code/document-info",
    name: "promo-code/document-info",
    component: () =>
      import("@/views/default/document/promocodeDocumentInfo.vue"),
  },
  {
    path: "/infoOrderMap",
    name: "infoOrderMap",
    component: () => import("@/views/default/orderMap/infoOrderMap.vue"),
  },
  {
    path: "/infoDriver",
    name: "infoDriver",
    component: () =>
      import("@/views/default/document/historyFlesh/infoDriver.vue"),
  },
  {
    path: "/infoLogin",
    name: "infoLogin",
    component: () =>
      import("@/views/default/document/historyFlesh/infoLogin.vue"),
  },
  {
    path: "/driversAction",
    name: "driversAction",
    component: () =>
      import("@/views/default/document/historyFlesh/driversAction.vue"),
  },
  {
    path: "/order/order-history",
    name: "orderHistory",
    component: () => import("@/views/default/information/orderHistory.vue"),
  },
  {
    path: "/car-colors",
    name: "car-settings/car-colors",
    component: () => import("@/views/default/taxi/cars/CarColors"),
  },
  {
    path: "/car-settings/model-cars",
    name: "car-settings/model-cars",
    component: () => import("@/views/default/taxi/cars/model-car.vue"),
  },
  {
    path: "/car-settings/categories",
    name: "car-settings/categories",
    component: () => import("@/views/default/taxi/cars/categories.vue"),
  },
  {
    path: "/car-settings/classes",
    name: "car-settings/classes",
    component: () => import("@/views/default/taxi/cars/classes.vue"),
  },
  {
    path: "/car-settings/dop-options",
    name: "car-settings/dop-options",
    component: () => import("@/views/default/taxi/driver/dop-options.vue"),
  },
  {
    path: "/taxi/driver/drivers-history-orders",
    name: "drivers/drivers-history-orders",
    component: () =>
      import("@/views/default/taxi/driver/driverHistoryOrders.vue"),
  },
  {
    path: "/order/history-call",
    name: "orderHistoryCall",
    component: () => import("@/views/default/information/orderHistoryCall.vue"),
  },
  {
    path: "/divisions",
    name: "divisions",
    component: () => import("@/views/default/settings/divisionsTaxi/index.vue"),
  },
  {
    path: "/organizations",
    name: "organizations",
    component: () =>
      import("@/views/default/taxi/organization/typeOrganization.vue"),
  },
  {
    path: "/orgtype",
    name: "orgtype",
    component: () => import("@/views/default/settings/orgtype/index.vue"),
  },
  {
    path: "/driver-profile-statuses",
    name: "driver-profile-statuses",
    component: () =>
      import("@/views/default/settings/driver-profile-statuses/index.vue"),
  },
  {
    path: "/v2/allowances/pattern",
    name: "v2/allowances/pattern",
    component: () =>
      import("@/views/default/tariff/allowanceParameter/index.vue"),
  },
  {
    path: "/message-templates",
    name: "message-templates",
    component: () =>
      import("@/views/default/settings/messageTemplate/index.vue"),
  },
  {
    path: "/poicategories",
    name: "poicategories",
    component: () => import("@/views/default/settings/poiCategory/index.vue"),
  },
  {
    path: "/slag-list",
    name: "slag-list",
    component: () => import("@/views/default/settings/slugs/index.vue"),
  },
  {
    path: "/app-versions",
    name: "app-versions",
    component: () =>
      import("@/views/default/settings/versionControl/index.vue"),
  },
  {
    path: "/type-transaction",
    name: "type-transaction",
    component: () =>
      import(
        "@/views/default/settings/TransactionDocumentType/transactionDocumentType.vue"
      ),
  },
  {
    path: "/driver-performer",
    name: "driver-performer",
    component: () =>
      import(
        "@/views/default/carStateCurrent/referenceFieldFullName/index.vue"
      ),
  },
  {
    path: "/car-performer",
    name: "car-performer",
    component: () =>
      import("@/views/default/carStateCurrent/referenceFieldCar/index.vue"),
  },
  {
    path: "/history-state",
    name: "history-state",
    component: () =>
      import("@/views/default/carStateCurrent/referenceHistoryState/index.vue"),
  },
  {
    path: "/performer-map",
    name: "performer-map",
    component: () => import("@/views/default/carStateCurrent/performerMap.vue"),
  },
  {
    path: "/showOnThe-map",
    name: "showOnThe-map",
    component: () => import("@/views/component/Map/orderAndJournals/index.vue"),
  },
];
