<template>
  <b-row style="margin-bottom: 5px">
    <b-col cols="2">
      <p>{{ field.label }}</p>
    </b-col>
    <b-col cols="5">
      <input
        :value="
          value.filter_from_start_time
            ? value.filter_from_start_time.replaceAll(' ', 'T')
            : ''
        "
        @input="updateModel($event.target.value, 'filter_from_start_time')"
        type="datetime-local"
        class="form-control form-control-sm"
      />
      <!-- <label for="" style="display:block; text-align: right;">От</label>
        <date-picker
            :value="value.filter_from_start_time ? value.filter_from_start_time.replaceAll('T', ' ') :''"
            @input="updateModel($event, 'filter_from_start_time')"
            type="datetime"
            class="w-100"
            v-mask="'##:## ##-##-####'"
            format="YYYY-MM-DD HH:mm"
            :value-type="'YYYY-MM-DD HH:mm'"
            placeholder="YYYY-MM-DD HH:mm"
        >
        </date-picker> -->
    </b-col>
    <b-col cols="5">
      <input
        :value="
          value.filter_from_end_time
            ? value.filter_from_end_time.replaceAll(' ', 'T')
            : ''
        "
        @input="oldUpdate($event.target.value, 'filter_from_end_time')"
        type="datetime-local"
        class="form-control form-control-sm"
      />
      <!-- <label for="" style="display:block; text-align: right;">До</label>
        <date-picker
            :value="value.filter_from_end_time ? value.filter_from_end_time.replaceAll('T', ' ') :''"
            @input="updateModel($event, 'filter_from_end_time')"
            class="w-100"
            type="datetime"
            v-mask="'##:## ##-##-####'"
            format="YYYY-MM-DD HH:mm"
            placeholder="YYYY-MM-DD HH:mm"
            :value-type="'YYYY-MM-DD HH:mm'"
        >
        </date-picker>  -->
    </b-col>
  </b-row>
</template>
<script>
import { mask } from "vue-the-mask";
export default {
  directives: { mask },
  props: ["value", "field"],
  name: "date_start_time",
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {};
  },
  methods: {
    updateModel(value, name) {
      let date = { field: name };
      date.val = value ? value.replaceAll("T", " ") : "";
      this.$emit("change", date);
    },
  },
  mounted() {
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "start_time" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "filter_from_start_time";
      this.updateModel(value, name);
    }
    if (
      this.$store.state.filterPlus.filterPlus !== null &&
      this.$store.state.filterPlus.keyFilter === "end_time" &&
      this.$store.state.filterPlus.addFilterPlus
    ) {
      const value = this.$store.state.filterPlus.filterPlus;
      const name = "filter_from_end_time";
      this.updateModel(value, name);
    }
  },
};
</script>

<style scoped>
th,
td {
  width: auto !important;
}
</style>
