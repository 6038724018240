import JwtService from "@/@core/auth/jwt/jwtService";

export default function checkToken(axiosIns) {
  const refreshInterval = 1800000;
  const checkInterval = 60000;

  const checkAndRefresh = () => {
    let data = JSON.parse(localStorage.getItem("userData"));
    if (!data) return;
    if (!isTokenExpired(data)) {
      handleTokenExpiration();
      clearInterval(checkIntervalId);
      clearInterval(refreshIntervalId);
      return;
    }
  };

  const checkIntervalId = setInterval(checkAndRefresh, checkInterval);

  const refreshIntervalId = setInterval(
    () => refreshToken(axiosIns),
    refreshInterval
  );

  checkAndRefresh();
}

function isTokenExpired(data) {
  const expirationTime = new Date(data.access_token_expDate).getTime();
  const currentTime = new Date(data.created_at).getTime();
  return expirationTime >= currentTime;
}

function refreshToken(axiosIns) {
  let data = JSON.parse(localStorage.getItem("userData"));
  if (!data || !data.refresh_token) return;

  const formData = new FormData();
  formData.append("refresh_token", data.refresh_token);

  axiosIns
    .post(`auth/refresh-token/${data.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      const updatedData = {
        ...data,
        access_token: res.data.access_token,
        refresh_token: res.data.refresh_token,
        access_token_expDate: res.data.access_token_expDate,
        created_at: res.data.created_at,
      };
      localStorage.setItem("userData", JSON.stringify(updatedData));

      const jwtService = new JwtService(axiosIns);
      jwtService.setToken(res.data.access_token);

      axiosIns.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.access_token}`;
    })
    .catch((error) => {
      console.error("Ошибка обновления токена:", error);
    });
}

function handleTokenExpiration() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userData");
  window.location.href = "/login";
}
